* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}

button {
  border: none;
}

input:focus,
textarea:focus,
select:focus {
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(51, 98, 113, 0.6) !important;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(51, 98, 113, 0.6) !important;
}

select {
  cursor: pointer;
}

input[type='radio'] {
  cursor: pointer;
}

a {
  text-decoration: none !important;
}

a:hover {
  color: #336271 !important;
}

.book-type {
  cursor: pointer;
}

.container {
  overflow: hidden;
}

.green-link {
  color: #505F79 !important;
  text-decoration: none;
  font-family: 'Raleway';
  cursor: pointer;
  font-weight: 300;
}

.green-link:hover {
  opacity: 0.7;
}

.green-text {
  color: #234241;
}

.light-green-text {
  color: #374242;
}

.light-gray-text {
  color: #505F79;
  opacity: 0.7;
}

.form-label {
  margin-top: 5px;
  margin-bottom: 0 !important;
}

.opacity-6 {
  opacity: 0.6;
}

.red-btn {
  width: 200px;
  min-height: 64px;
  background: rgba(238, 90, 90, 0.2);
  color: #AE1A1A;
  font-weight: bold;
  font-size: 22px;
  line-height: 33px;
  border: 3px solid #EE5A5A;
  border-radius: 50px;
  text-align: center;
  text-decoration: none;
  padding-top: 12px;
}

.red-btn:hover {
  color: #AE1A1A;
  opacity: 0.8;
}

.green-btn {
  background: #587C7E;
  border-radius: 50px;
  width: 165px;
  height: 40px;
  color: #fff;
  font-weight: bold;
}

.green-btn:hover {
  color: #587C7E;
  background: #fff;
  border: 2px solid #587C7E;
}

.green-btn-sec {
  background: #E6F1F2;
  border: 0.5px solid #487380;
  border-radius: 50px;
  width: 165px;
  height: 40px;
  color: #487380;
  font-weight: bold;
}

.green-btn-sec:hover {
  opacity: 0.6;
}

.green-btn-sec.sm {
  width: 120px;
  height: 30px;
  font-size: 16px;
}

.brown-btn {
  background: #fff;
  border-radius: 50px;
  width: 165px;
  height: 40px;
  color: #6A5630;
  font-weight: 700;
  border: 2px solid #A6926C;
}

.brown-btn:hover {
  background: rgba(242, 210, 145, 0.2);
  transform: scale(0.98);
}

.gray {
  color: #6B778C;
}

.brown {
  color: #8E6E2D !important;
  accent-color: #8E6E2D;
}

.input-err {
  border: 1px solid red !important;
}

.page-title {
  color: #6B778C;
  font-family: 'Raleway';
  font-weight: 600;
}

/* Header */
#nav-container {
  position: relative;
  border-bottom: 1px solid #709496;
  overflow: visible !important;
}

.navbar {
  padding: 0 !important;
}

.dropdown-menu[data-bs-popper] {
  left: -120px !important;
  top: 35px !important;

}

.dropdown-menu {
  z-index: 10 !important;
}

.fa-magnifying-glass {
  right: -7px;
  font-size: 20px;
  padding: 7.8px 15px;
  background: rgba(112, 148, 150, 0.04);
  border-radius: 0px 5px 5px 0px;
  color: rgba(51, 98, 113, 0.5);
}

input[type="search"] {
  padding-left: 30px;
  margin-right: 30px;

}

#tablet-form {
  display: none !important;
}

#desktop-form {
  display: flex !important;
}

#search-box {
  font-size: 15px;
  padding-left: 20px;
  padding-right: 50px;
  background: #FFFFFF;
  border: 1px solid rgba(107, 119, 140, 0.6);
  border-radius: 5px;
  width: 70%;
  transition: all 0.3s ease;
}

#search-box:focus {
  width: 100%;
}

#search-box:hover {
  width: 100%;
}

.logo-img {
  width: 120px;
  height: 40px;
}

.nav-link {
  margin-top: 10px !important;
  color: #B3BAC5 !important;
  font-weight: 500 !important;
  cursor: pointer;
  font-size: 17px !important;
}

.nav-link-active {
  color: #336271 !important;
  position: relative;
}

.nav-link-active::before {
  content: '';
  position: absolute;
  bottom: 10px;
  right: 10%;
  background: #F2D291;
  height: 2px;
  width: 60%;
  position: absolute;
}

.nav-link:hover {
  opacity: 0.7;
}

.navbar-toggler:focus {
  box-shadow: none !important;
}

.splash {
  position: absolute;
  width: 150px;
  height: 150px;
  left: 43%;
  top: -75px;
  z-index: -1;
  opacity: 0.4;
  animation: splash-anim 1.5s;
}

.login-btn {
  min-width: 78px;
  background-color: rgba(190, 219, 215, 0.3) !important;
  border: 1px solid #487380 !important;
  color: #487380 !important;

}

.login-btn:hover {
  color: #fff !important;
  background-color: #487380 !important;
}

@keyframes splash-anim {
  0% {
    opacity: 1;
    transform: rotate(720deg);
  }

  100% {
    transform: rotate(0);
  }

}

.splash-1,
.splash-2,
.splash-3 {
  width: 50px;
  height: 50px;
  transform: none;
}

.splash-1 {
  left: 12%;
  top: -30px;
}

.splash-2 {
  top: -30px;
  left: 84%;
}

.splash-3 {
  position: absolute;
  top: 56px;
  left: 63%;
  z-index: -1;
  height: 30px;
  z-index: -1;
  opacity: 0.4;
}

.cart {
  border-radius: 50%;
  cursor: pointer;
  padding: 15px;
  transition: all 0.3s ease;
}

.cart:hover {
  background: rgba(217, 217, 217, 0.2);
}

.profile-btn {
  border-radius: 50%;
  cursor: pointer;
  padding: 15px;
  transition: all 0.3s ease;
}

.profile-btn a {
  margin-top: 6px !important;
}

.profile-btn:hover {
  background: rgba(217, 217, 217, 0.2);
}

.language-switch>div {
  cursor: pointer;
  width: 35px;
  height: 35px;
  background: url('./assets/globe.png') no-repeat center center /contain;
}

.language-switch small {
  font-size: 10px;
  padding: 0;
  margin: 0;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  color: #A4ACB9;
}

.language-switch {
  border-radius: 50%;
  cursor: pointer;
  padding: 15px;
  transition: all 0.3s ease;
}

.language-switch:hover {
  background: rgba(217, 217, 217, 0.2);
}

/* Hero */

#hero {
  margin: auto;
  margin-bottom: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1728px;
  height: 584px;
  overflow: hidden;
  z-index: 2;
}

.hero-ilustration {
  object-fit: cover;
  object-position: center center;
  width: 653px;
  height: 574px;
}

.hero-ilustration.literature {
  width: 800px;
}

.hero-2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: start;
  padding-left: 100px;
  height: 100%;
  width: 40%;
  color: #374242;
  font-family: 'Pacifico', cursive;
  background: url('./assets/new-banner.jpg') no-repeat 0% 0% /cover;
  background-size: 1000px;
  position: relative;
  z-index: 1;
}

.hero-2::before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: auto;
  width: auto;
  background: url('./assets/glass.png') center center /contain;
  z-index: -1;
}

.hero-2 h1 {
  font-family: 'Pacifico', cursive;
  font-size: 50px;
  max-width: 450px;
  line-height: 100px;
}

.hero-2 p {
  font-family: 'Pacifico', cursive;
  font-size: 25px;
}

.hero-2-img {
  width: 60%;
  object-fit: cover;
  height: 100%;
  object-position: center 55%;
}

.future-book-header {
  background-image: url('./assets/book-shelter.jpg');
  background-repeat: repeat;
  background-size: contain;
  padding: 0 20px;
}

.future-launch {
  width: 355px;
  height: 513px;
  border-radius: 5px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.upcoming-book {
  width: 718px;
  height: 536px;
  border-radius: 6px;
  padding: 30px 30px 0 30px !important;
  position: relative;
}

.upcoming-book::before {
  content: '';
  position: absolute;
  background: rgba(250, 251, 252, 0.8);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  filter: blur(5px);
  z-index: -1;
}

.upcoming-book h1 {
  color: #336271 !important;
  z-index: 5;
  margin-left: 0px;
  margin-bottom: 20px;
  text-align: center;
}

.upcoming-book p {
  margin-top: 10px !important;
  font-size: 20px !important;
  padding: 0 35px;
  font-family: 'Raleway';
  font-weight: 400 !important;
  color: #374242;
  line-height: 28px !important;
}

.upcoming-book .green-btn-sec {
  width: 200px;
  min-height: 60px;
  height: auto;
  text-align: center;
  padding-top: 13px;
  font-size: 22px;
  font-family: 'Raleway';
}

.new-sign-up {
  text-decoration: underline !important;
}

.carousel-div {
  max-width: 1980px;
  margin: auto;
}

.carousel-indicators [data-bs-target] {
  background-color: #000 !important;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  height: 70px !important;
  width: 70px !important;
}

.carousel-control-prev-icon {
  background-image: url('./assets/chevron-left.png') !important;
}

.carousel-control-next-icon {
  background-image: url('./assets/chevron-right.png') !important;
}

.carousel-control-next,
.carousel-control-prev {
  width: 5% !important;
  z-index: 6 !important;
}

.subscribe-form>div {
  width: 100%;
}

.subscribe-form input[type="email"] {
  border-radius: 0.375rem 0 0 0.375rem;
  border: 1px solid rgba(107, 119, 140, 0.8) !important;
  padding: 14px;
}

.subscribe-form input[type="submit"] {
  border: 1px solid rgba(107, 119, 140, 0.8);
  width: 200px;
  border-left: none;
  padding: 14px;
  font-weight: 600;
  font-size: 14px;
  color: #336271;
  background: rgba(117, 164, 167, 0.2);
  border-radius: 0px 10px 10px 0;
}

.newsletter-warning {
  font-size: 11px !important;
  line-height: 15px !important;
  opacity: 0.5;
}

.ellipse {
  position: absolute;
  left: -520px;
  bottom: 85px;
  z-index: -1;
  width: 1363.6px;
  border-radius: 50%;
  height: 629px;
  background: linear-gradient(180deg, #F4C1B9 7.46%, #E1DBD4 61.2%, #D4ECE7 95.53%);
  filter: blur(2px);
  transform: rotate(-155.36deg);
}

.context {
  max-width: 635px;
  padding-right: 30px;
  z-index: 2;
}

.context h1 {
  font-family: 'Pacifico', cursive;
  font-weight: 400;
  font-size: 72px;
  line-height: 110.43px;
  color: #336271;
}

.context span {
  font-size: 18px;
  color: #374242;
  font-family: 'Raleway';
  font-weight: 500;
  color: #374242;
}

.context p {
  margin-left: auto;
  text-align: start;
  font-size: 18px;
  margin-top: 25px;
  font-weight: 300;
  line-height: 20px;
}

.cta-actions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

.cta-actions small a {
  color: #374242;
  font-size: 16px;
  font-weight: 700;
}

.cta-actions a:hover {
  opacity: 0.7;
}

/* Book Card */
.book-card {
  display: flex;
  justify-content: center;
  width: 285px;
  height: 390px;
  margin: 0 5px;
  border: none !important;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  position: relative;
  overflow: hidden;
}

.book-card img {
  width: 100%;
  height: 100%;
}

.remove-btn {
  color: #742b3d;
  font-size: 25px !important;
  font-weight: bold;
  z-index: 5 !important;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 15px;

}

.remove-btn:hover {
  transform: scale(0.9);
}

.book-info {
  cursor: pointer;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 5px 15px;
  height: 100%;
  width: 100%;
  display: flex;
  transform: translateY(-330px);
  opacity: 0;
  flex-direction: column;
  justify-content: flex-end;
  text-decoration: none;
  color: #234241;
  transition: all 0.5s ease;
}

.book-info>div:first-of-type {
  cursor: pointer;
}

@media(hover: hover) and (pointer: fine) {
  .book-card:hover .book-info {
    opacity: 1;
    transform: translateY(0);
  }
}

@media(hover: none) and (pointer: coarse) {
  .book-info.show {
    opacity: 1;
    transform: translateY(0);
  }
}

.book-info p {
  margin: 0;
  line-height: normal;
  font-size: 14px;
  font-family: 'Roboto';
  font-weight: 400;
  color: #234241;
}

.book-info small {
  z-index: 5;
  padding-top: 8px;
  font-family: 'Roboto';
  font-weight: 400;
  color: #234241;
}

.book-info span {
  font-size: 14px;
  font-family: 'Roboto';
  color: #234241;
}

.book-info::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  background: rgba(242, 199, 194, 0.8);
  transform: matrix(1, 0, 0, 1, 0, 0);
  z-index: 1;
}

.book-info::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 50%;
  height: 50%;
  background: linear-gradient(0.62deg, rgba(117, 164, 167, 0.9) 70.75%, rgba(161, 195, 194, 0) 99.93%);
  transform: matrix(1, 0, 0, 1, 0, 0);
  z-index: 2;
}

.book-title {
  font-size: 16px !important;
  margin: 0;
  font-weight: 700 !important;
  z-index: 2;
  color: #234241;
  cursor: pointer;
  font-family: 'Raleway' !important;
}

.author-title {
  z-index: 2;
  margin-bottom: 5px;
}

.author-title:hover {
  opacity: 0.7;
}

.fa-star {
  color: #f1c40f;
  z-index: 2;
}

.book-card-action {
  z-index: 8;
}

.add-to-watchlist {
  z-index: 2;
  margin-right: 10px;
  display: flex;
  align-items: center;
  font-size: 20px;
  cursor: pointer;
}

.prev-pdf {
  cursor: pointer;
}

.vertical-line {
  height: 50px;
  width: 1px;
  margin: 0 10px;
  background: #DFE1E6;
}

.buy-btn {
  margin: 5px;
  width: 100px;
  height: 25px;
  text-decoration: none;
  border-radius: 50px;
  color: #6A5630;
  border: 0.2px solid #A6926C;
  font-size: 12px;
  z-index: 2;
  text-align: center;
  background: #FFFFFF;
  font-weight: bold;
  font-family: 'Raleway';
  font-weight: 600;
}

.book-language {
  min-width: 280px;
  display: flex;
  padding: 8px 15px 8px 8px;
  justify-content: space-between;
  border: 0.6px solid #DFE1E6;
  border-radius: 4px;
}

.select-language {
  width: 80px;
  height: 31px;
  border: 0.3px solid #DFE1E6;
  border-radius: 2px;
  padding: 0 10px;
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image:
    linear-gradient(50deg, transparent 50%, #8E6E2D 50%),
    linear-gradient(130deg, #8E6E2D 50%, transparent 50%);
  background-position:
    calc(99% - 20px) calc(0.5em + 2px),
    calc(100% - 15px) calc(0.5em + 2px);
  background-size:
    6px 12px,
    6px 12px;
  background-repeat: no-repeat;
}

.buy-btn:hover {
  transform: scale(0.98);
  color: #774E58;
}

@media (hover:hover) and (pointer: fine) {

  .buy-btn:hover .charity-msg {
    display: block;
  }
}

.description {
  z-index: 2;
}

.book-price {
  z-index: 2;
  font-weight: bold;
  font-size: 20px;
  margin-top: 5px;
  color: #F2D291;
}

.book-name {
  max-width: 500px;
  font-weight: 600;
  color: #374242;
}

.social-initiatives {
  object-fit: contain;
  width: 100%;
  height: 100%;
  margin-bottom: 50px;
}

/* Swiper */
.swiper {
  padding: 0 50px !important;
}

.swiper-wrapper {
  padding-bottom: 20px;
}

.swiper-button-next,
.swiper-button-prev {
  color: #292A2E !important;
  height: 60px !important;
  width: 60px !important;
}

.swiper-button-prev {
  background: url('./assets/chevron-left.png')no-repeat center center /cover !important;
}

.swiper-button-next {
  background: url('./assets/chevron-right.png')no-repeat center center /cover !important;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  display: none;
}

.swiper-slide {
  width: 310px !important;
}

/* Footer */
.footer {
  color: #fff;
  padding: 50px 0;
  position: relative;
  overflow: hidden;
  background: linear-gradient(0deg, rgba(242, 210, 145, 0.336) 0.03%, rgba(255, 255, 255, 0.4) 100%);
}

.footer .container {
  display: flex;
  justify-content: space-between;
}

.footer h3 {
  color: #374242;
  font-size: 20px;
  font-weight: 600;
}

.footer p {
  margin-bottom: 10px;
  font-size: 14px;
  color: #374242;
}

.footer a {
  color: #374242;
}

.footer a:hover {
  color: #374242;
  opacity: 0.7;
}

.address {
  margin-top: 40px;
  margin-bottom: 20px;
}

.address h5 {
  color: #374242;
  font-size: 20px;
  font-weight: 600;
}

.social {
  z-index: 2;
}

.social p {
  font-size: 14px;
  font-weight: 600;
}

.media-icons a {
  font-size: 26px;
  margin: 0 10px;
}

/* Tours */
#tours {
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#tours video {
  margin: 0 auto;
  width: 80%;
  height: 584px;
  background-color: #000;
}

.tour-description {
  padding: 30px;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  vertical-align: center;
}

.tour-description h2 {
  font-weight: 600;
}

.tour-description p {
  max-width: 600px;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

/*  initiatives  */
#initiatives h2 {
  font-weight: 600;
}

.card-box {
  margin: 15px;
  width: 415px;
  height: 287px;
  background: #D9D9D9;
  border-radius: 5px;
}

/* Explore places */
.places-card {
  transition: all 0.1s ease;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.places-card img {
  width: 300px;
  height: 400px;
  object-fit: cover;
  z-index: 2;
  margin-bottom: 20px;
}

.places-card p {
  margin-top: 20px;
  text-align: center;
  color: #374242;
  position: relative;
}

.places-card:hover p::after {
  position: absolute;
  content: '';
  right: 32%;
  bottom: 0;
  width: 25%;
  height: 3px;
  background-color: #F2D291;
}

.places-card.stuttgart p::after {
  width: 50%;
  right: 12%;
}

.places-card>div {
  width: 250px;
  height: 450px;
  border: 2px solid #75A4A7;
  border-radius: 2px;
  position: absolute;
  z-index: -1;
  right: 35px;
  top: -25px;
}

.places-card:hover>div {
  border: 4px solid #75a4a7;
}

.places-box {
  border: 2px solid rgba(166, 146, 108, 0.75) !important;
}

.places-card:hover .places-box {
  border: 4px solid rgba(166, 146, 108, 0.75) !important;
}

/* About screen */
.about-title::before {
  content: '';
  position: absolute;
  bottom: -2px;
  right: 38%;
  background: #F2D291;
  height: 2px;
  width: 150px;
  position: absolute;
}

.conv-box {
  background: rgba(117, 164, 167, 0.04);
  padding: 20px;
  position: relative;
  margin: 0 20px;
  min-height: 1200px;
  color: #374242;
}

.conv-closing {
  font-weight: 500;
  color: #374242;
}

.about-img {
  width: 50%;
}

.about-img-visible {
  width: 115%;
  height: auto;
  position: absolute;
  bottom: 0;
  left: -90px;
}

.about {
  margin: 80px 0;
}

.about h2 {
  margin-bottom: 30px;
  font-weight: bold;
}

.about-image {
  z-index: 5;
  width: 500px;
  height: 500px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 5px;
}

.text-box {
  max-width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding: 0 50px;
  color: #fff;
}

.after-bg::after {
  content: '';
  position: absolute;
  z-index: -1;
  top: 50px;
  left: 0;
  right: -150px;
  bottom: 0;
  height: 80%;
  background-color: #75A4A7;
  opacity: 0.7;
  border-radius: 10px 0 0 10px;
}

.about.initiatives {
  margin-top: 150px !important;
}

.initiatives-image {
  width: 600px;
  height: 300px;
  object-fit: cover;
}

.meet-up {
  margin-top: 70px;
  margin-bottom: 50px;
  position: relative;
  padding: 30px;
  color: #fff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 10px;
  background-color: rgb(244, 193, 185);
}

.team-link {
  position: absolute;
  left: 50%;
  bottom: 60px;
  transform: translateX(-50%);
  color: #234241 !important;
  font-size: 25px;
  z-index: 8;
  font-family: 'Raleway';
}

.team-link:hover::after {
  position: absolute;
  content: '';
  right: 0;
  bottom: 0;
  width: 60%;
  height: 3px;
  background-color: #F2D291;
}

/* Books page */
#books-screen {
  min-height: 100vh;
}

.book-collection {
  width: 100%;
}

.book-collection .green-link {
  font-weight: 400 !important;
}

.category-mobile {
  display: none;
}

.language-mobile {
  display: none;
}

.filter {
  width: 180px !important;
  max-width: 180px;
  overflow-y: auto;
  min-height: auto;
  padding: 10px 20px;
  margin-right: 80px;
  border-right: 1px solid #C1C7D0;
}

.filter-active {
  font-weight: bold;
  position: relative;
}

.filter-active::before {
  content: '';
  position: absolute;
  right: 0;
  bottom: 0;
  width: 70%;
  height: 2px;
  background-color: #F2D291;
}

.filter li {
  list-style: none;
}

.filter p {
  cursor: pointer;
  text-align: center;
}

.filter p:hover {
  opacity: 0.7;
}

.book-page-card img {
  margin-right: 60px;
  width: 416px;
  height: 595px;
}

.book-page-description {
  border-radius: 5px;
  color: #374242;
  padding: 15px;
  padding-bottom: 0px;
}

.social-contrib {
  max-width: 400px;
  color: #374242;
}

.product-info {
  max-width: 400px;
  color: #374242;
}

.window-blur {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 7;
}

.book-preview {
  position: absolute;
  z-index: 8;
  width: 100%;
  bottom: 0;
  left: 0;
  height: 100%;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  overflow-y: scroll;
}

.book-preview-div {
  position: absolute;
  border-radius: 5px 5px 0 0;
  width: 150px;
  top: 0;
  right: 0;
  height: 60px;
  background: rgb(242, 199, 194);
  z-index: 9;
}

.available-text {
  color: #234241;
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 600;
  font-size: 12px;
  margin-top: 5px;
  text-align: center;
}

.fa-circle-xmark {
  position: absolute;
  top: 10px;
  right: 20px;
  z-index: 10;
  font-size: 40px;
  color: #a3092f;
  cursor: pointer;
}

.fa-circle-xmark:hover {
  opacity: 0.6;
}

.wishlist-btn {
  border-radius: 50%;
  background: rgba(238, 165, 161, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 60px;
  height: 60px;
  margin-left: 15px;
  cursor: pointer;
}

.wishlist-btn:hover {
  transform: scale(0.98);
}

.book-description {
  max-height: 300px;
  overflow-y: auto;
  margin-bottom: 30px;
}

.author-description {
  margin: 80px 0;
  margin-top: 0px;
  position: relative;
  padding-top: 30px;
}

.author-desc-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 30px;
  margin-top: 25px;
}

.author-description img {
  width: 250px !important;
  max-height: 350px !important;
  object-fit: cover;
}

.author-description p {
  margin: auto;
  color: #374242;
  max-width: 800px;

}

.h-line-break {
  height: 320px;
  width: 1px;
  background: #DFE1E6;
  margin: 0 40px;
}

#short-video iframe {
  width: 100%;
  height: 500px;
}

.set-qty input {
  margin-left: 3px;
  padding-left: 8px;
  width: 40px;
  font-weight: bold;
  border-radius: 5px;
  border: none;
  color: #774E58;
  text-align: center;
}

.set-qty button {
  background: transparent;
  font-weight: bold;
  font-size: 15px;
  padding: 0;
}

/* form container  */
#form-container {
  min-height: 65vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#form-container a {
  color: #774E58;
}

.btn-primary {
  background-color: #8A3344 !important;
  border: 1px solid #8A3344 !important;
}

.btn-primary:hover {
  background-color: #F4C1B9 !important;
}

/* Cart */
.shopping-cart {
  min-height: 80vh;
}

#cart-select-qty {
  border: 1px solid #292A2E;
  width: 40px;
  padding: 0 5px;
}

div[aria-labelledby="cart-select-qty"] {
  max-height: 100px;
  min-width: 50px;
  overflow-y: scroll;
  z-index: 10;
}

.select-qty-dropdown {
  padding: 0 5px !important;
}

#cart-notification {
  top: 15px !important;
  width: 20px;
  height: 20px;
  text-align: center;
  padding: 5px;
}

/* Reviews */
.reviews-box {
  width: 65%;
  margin-top: 20px;
  margin-left: 50px;
}

.review-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 10px;
  padding: 10px 20px;
  padding-left: 50px;
  color: #336271;
  height: 200px !important;
  margin-right: 10px !important;
  margin-top: 20px;
}

.swiper-slide.review-card {
  width: 102% !important;
}

.review-comment {
  max-height: 100px;
  overflow-y: auto;
}

.write-review {
  max-width: 800px;
}

/* pagination */
.pagination {
  justify-content: center;
  margin-top: 20px;
}

.paginate-btn {
  padding: 8px 15px;
  border: 1px solid #FDE6DC;
  margin: 30px 0px;
  margin-right: 5px;
  font-weight: bold;
  color: #336271;
  background: #FDE6DC;
  border-radius: 10px;
}

.paginate-btn:hover {
  scale: 0.95;
}

/* User Profile */
.user-profile {
  min-height: 80vh;
}

#place-order-screen {
  min-height: 80vh;
}

/* Blogs */
.blogs-div {
  padding: 0 50px;
  padding-top: 30px;
}

.blog-card {
  padding: 30px;
  margin-bottom: 120px;
  border: 1px solid rgba(166, 146, 108, 0.75);
  font-size: 14px;
  position: relative;
}

.blog-card::before {
  content: '';
  position: absolute;
  background: rgba(117, 164, 167, 0.08);
  top: -20px;
  left: 20%;
  height: 110%;
  width: 85%;
  border-radius: 2px;
  z-index: -1;
}

.blog-card:hover {
  border: 3px solid rgba(166, 146, 108, 0.75);
}

.blog-card.active {
  border: 3px solid rgba(166, 146, 108, 0.75);
}

.blog-card.odd.active {
  border: 3px solid #75A4A7;
}

.blog-card.odd {
  border: 1px solid #75A4A7;
}

.blog-card.odd::before {
  background: rgba(242, 199, 194, 0.15);
  top: -30px;
  left: -40px;
  height: 113%;
}

.blog-card.team {
  margin-bottom: 20px;
}

.blog-card.team::before {
  top: -35px;
  left: -40px;
  height: 120%;
}

.blog-card.odd.team {
  margin-bottom: 20px;
}

.about-team {
  max-width: 320px;
}

.blog-card.odd:hover {
  border: 3px solid #75A4A7;
}

.blog-card img {
  width: 250px;
  max-height: 300px;
  object-fit: cover;
}

.extended {
  width: 300px !important;
  max-height: 450px !important;
}

.blog-text {
  max-width: 700px;
}

.blog-card button {
  background: none;
  text-decoration: underline;
  font-weight: bold;
}

.hr-line {
  height: 150px;
  width: 2px;
  background-color: #DFE1E6;
}

.blog-card.team-sec {
  margin-top: 40px;
  position: relative;
  height: max-content !important;
  max-width: 600px !important;

  &p {
    max-width: 586px;
  }

}

.blog-card.team-sec::before {
  width: 100%;
  left: 5%;
}


/* Places */
.places-image {
  height: 388px;
  width: 100%;
  object-fit: none;
  object-position: 80% 45%;
  opacity: 20%
}

.post-description {
  white-space: pre-wrap;
}

.bookshop {
  border: 1px solid rgba(166, 146, 108, 0.75);
  border-radius: 2px;
  position: relative;
}

.bookshop-img {
  width: 350px;
  height: 500px;
  object-fit: cover;
}

.bookshop-img-sm {
  width: 180px;
  height: 250px;
}

.bookshop-right {
  max-width: 600px;
  font-size: 15px;
}

.bookshop::before {
  content: '';
  position: absolute;
  right: -10%;
  top: -5%;
  width: 80%;
  height: 110%;
  background: rgba(117, 164, 167, 0.08);
  border-radius: 2px;
  z-index: -1;
}

.places-title {
  color: #234241;
  font-family: 'Raleway';
  font-weight: 700;
  max-width: auto;
}

.underline {
  opacity: 0;
  position: absolute;
  bottom: 10px;
  right: 0;
  left: 45%;
  background: #F2D291;
  height: 2px;
  width: 230px;
}

.bookshop-underline {
  left: 50%;
  width: 280px;
}

.korean-bookshop {
  background: rgba(117, 164, 167, 0.04);
  padding: 30px;
}

.places-blog-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: start;
  background: none;
  padding: 40px 30px;
  border: 1px solid #75A4A7;
  border-radius: 2px;
  position: relative;
  margin-bottom: 80px;
}

.place-box:hover .places-blog-card {
  border: 3px solid #75A4A7;
}

.place-box:hover .underline {
  opacity: 1;
}

.place-box-2:hover .bookshop {
  border: 3px solid rgba(166, 146, 108, 0.75);
}

.place-box-2:hover .underline {
  opacity: 1;
}

.places-blog-card::before {
  content: '';
  position: absolute;
  top: -30px;
  left: -40px;
  height: 110%;
  width: 75%;
  background: rgba(242, 199, 194, 0.15);
  z-index: -1;
}

.places-blog-card>div {
  margin: 20px 0px;
  line-height: 23px;
  font-size: 15px;
}

.places-blog-card img {
  width: 350px;
  height: 500px;
  margin-right: 30px;
}

.stuttgart-card img {
  width: 100%;
  height: 400px;
  object-fit: cover;
}

.stuttgart-card .blog-card {
  max-width: 100%;
  background: none;
  margin-bottom: 0;
}

/* People & Events*/
.people-hero {
  max-height: 474px;
  object-fit: fill;
  margin: 0 auto;
  margin-bottom: 20px;
}

.team-img {
  width: 228px !important;
  height: 273px !important;
  object-fit: contain !important;
}

.book-tour {
  font-family: 'Raleway';
  font-weight: 700;
  color: #234241;
  position: relative;
}

.book-tour::after {
  position: absolute;
  content: '';
  right: 0%;
  bottom: 0;
  width: 50%;
  height: 3px;
  background-color: #F2D291;
}

.event-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
  height: 368px;
  margin: 30px 40px;
  text-align: center;
  background-color: rgba(238, 165, 161, 0.08);
  border-radius: 3px;
  z-index: 5;
}

.event-card.event-odd {
  background-color: rgba(117, 164, 167, 0.08);
}

.event-name {
  color: #234241;
  font-family: 'Raleway';
  font-weight: 400;
}

.event-name span {
  font-family: 'Raleway';
  font-weight: 500;
}

.events-img {
  opacity: 4%;
  position: absolute;
  top: 100px;
  right: -180px;
  z-index: -1;
  width: 600px;
}

.event-img {
  position: relative;
  width: 500px;
  margin-top: 40px;
  margin-left: 30px;
  margin-bottom: 80px;
}

.event-img img {
  width: 500px;
  height: 700px;
}

.event-img::before {
  content: '';
  position: absolute;
  top: -30px;
  right: 0;
  bottom: 0;
  left: -30px;
  width: 100%;
  height: 110%;
  border: 2px solid rgba(166, 146, 108, 0.75);
  border-radius: 2px;
  z-index: -1;
}

.rotate-90 {
  transform: rotate(-90deg);
}

.events-collapse-btn {
  cursor: pointer;
  margin-bottom: 20px;
}

.events-container {
  min-height: 100vh;
}

.cookie-box {
  position: sticky;
  bottom: 0;
  left: 0;
  z-index: 10;
  padding: 20px 10px;
  color: #fff;
  background: rgba(0, 0, 0, 0.9);
  width: 100%;
}

.cookie-box>div {
  display: flex;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
}


@media screen and (max-width: 1400px) {

  .places-blog-card img {
    margin-right: 0px;
  }

  .bookshop-img {
    width: 300px;
    height: 420px;
    object-fit: cover;
  }

  .bookshop-img-sm {
    width: 180px;
    height: 250px;
  }

  .bookshop::before {
    width: 90%;
  }

  .swiper {
    padding: 0px !important;
    padding-right: 50px !important;
  }

  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    height: 60px !important;
    width: 60px !important;
  }
}

@media screen and (max-width: 1300px) {

  /* Hero */
  #hero {
    justify-content: space-between !important;
    margin-bottom: 0px;
  }

  .hero-ilustration {
    width: 453px;
    height: 374px;
  }

  .hero-2 {
    padding-left: 50px;
  }

  .hero-2 h1 {
    font-size: 40px;
    line-height: 70px;
  }

  .hero-2 p {
    font-size: 20px;
  }

  .about-img {
    left: -80px;
  }

  .team-link {
    bottom: 30px;
  }

  #tablet-form {
    display: flex !important;
  }

  #desktop-form {
    display: none !important;
  }

  .context h1 {
    font-size: 45px;
    line-height: 65px;
  }

  .context span {
    font-size: 18px;
  }

  .context p {
    font-size: 18px;
    margin-top: 15px;

  }

  .red-btn {
    width: 150px;
    min-height: 45px;
    font-size: 18px;
    padding-top: 3px;
  }

  .ellipse {
    width: 1163px;
    height: 550px;
    bottom: 65px;
  }

  .hero-ilustration.literature {
    width: 600px;
  }

  .subscribe-form {
    z-index: 10 !important;
  }

  .future-launch {
    margin-left: 60px;
  }

  .upcoming-book {
    height: 500px;
    width: 500px;
    margin-right: 50px;
  }

  .future-launch {
    margin-left: 0 !important;
  }

  .cta-actions {
    margin-top: 50px !important;
  }

  .upcoming-book .red-btn-sec {
    width: 150px;
    min-height: 45px;
    font-size: 16px;
    padding-top: 8px;
  }

  .reviews-box {
    width: 55%;
  }

  .blog-card p {
    font-size: 14px;
  }

  .h-line-break {
    margin: 0 20px;
  }

  .people-hero {
    max-height: 400px;
  }

  .event-card {
    margin: 20px 20px;
    height: 350px;
  }

  .book-type {
    font-size: 14px;
  }

  .places-blog-card>div {
    font-size: 13px;
  }

  .places-blog-card img {
    margin-right: 0px;
  }

  .bookshop-img {
    width: 260px;
    height: 400px;
  }

  .bookshop-img-sm {
    width: 160px;
    height: 230px;
  }

  .swiper {
    padding: 0px !important;
    padding-right: 50px !important;
  }

  .swiper-slide {
    width: 320px !important;
  }

}

@media screen and (max-width: 990px) {
  .navbar-collapse {
    text-align: center;
  }

  #search-box {
    width: 100% !important;
  }

  #nav-container {
    margin-top: 0 !important;
    padding-bottom: 20px !important;
    padding-top: 20px !important;
  }

  #hero {
    flex-direction: column-reverse !important;
    height: auto;
    margin-bottom: 50px;
  }

  .hero-ilustration {
    margin-bottom: 20px;
  }

  .hero-2-img {
    width: 100%;
    height: 500px;
  }

  .hero-2 {
    width: 100%;
    padding: 20px 0;
    text-align: center;
    align-items: center;
    background: none;
  }

  .splash {
    width: 100px;
    height: 100px;
    left: 40%;
    top: -50px;
  }

  .splash-1,
  .splash-2,
  .splash-3 {
    display: none;
  }

  .context {
    padding: 0;
    margin: 40px 0 50px 0;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    max-width: 550px;
  }

  .context p {
    text-align: center;
    max-width: 550px;
    margin-left: 0;
  }

  .future-launch {
    margin-left: 0;
    margin-bottom: 50px;
    height: 350px;
    width: 250px;
  }

  .ellipse {
    bottom: 90px;
  }

  .cta-actions {
    margin-top: 0;
  }

  .filter {
    width: 200px !important;
  }

  .book-price {
    font-size: 18px;
  }

  .add-to-watchlist {
    display: none;
  }

  .book-page-card {
    flex-direction: column;
  }

  .book-image-div {
    display: flex;
    justify-content: center;
    background: linear-gradient(360deg, #D3C7CA 20%, rgba(211, 199, 202, 0) 80%);
    border-radius: 5px;
    margin-bottom: 10px;
  }

  .book-page-card img {
    margin-right: 20px;
    width: 280px;
    height: 350px;
    border-radius: 5px;
  }

  .book-page-description {
    font-size: 14px;
  }

  .h-line-break {
    display: none;
  }

  .book-name {
    font-size: 24px;
  }

  .reviews-box {
    width: 100%;
    margin-top: 50px;
    margin-left: 0;
  }

  .author-description {
    margin: 50px 0;
  }

  .author-desc-box p {
    font-size: 14px;
    border-top: 1px solid #DFE1E6;
    border-right: none;
    padding-top: 15px;
    text-align: center;
    margin-right: 0;
  }

  .people-hero {
    max-height: 350px;
  }

  .carousel-control-next,
  .carousel-control-prev {
    width: 15% !important;
  }

  .event-card {
    margin: 20px auto;
  }

  .places-card>div {
    width: 220px;
    height: 450px;
  }

  .places-blog-card {
    display: flex;
    flex-direction: column;
    background: none;
    padding: 20px 20px;
    margin-bottom: 80px;
  }

  .underline {
    left: 20%;
  }

  .places-blog-card>div {
    max-width: 100%;
    line-height: 23px;
  }

  .places-blog-card img {
    width: 100%;
    object-fit: contain;
    margin: auto;
    margin-top: 20px;
  }

  .bookshop>div {
    display: flex;
    flex-direction: column;
  }

  .bookshop::before {
    display: none;
  }

  .bookshop-right {
    max-width: 100%;
  }

  .bookshop-right {
    font-size: 13px;
  }

  .about-title::before {
    right: 30%;
  }

  .hr-line {
    display: none;
  }

  .blogs-div {
    padding: 0;
  }

  .blog-card p {
    margin-top: 10px;
    font-size: 13px;
  }

  .blog-card {
    padding: 10px;
  }

  .blog-card.odd::before {
    height: 105%;
  }

  .extended {
    width: 250px !important;
    height: 400px !important;
  }

  .blog-name {
    display: none;
  }

  .newsletter-warning {
    display: none;
  }

  .stuttgart-card img {
    height: auto;
  }

  .stuttgart-blog-card img {
    height: auto;
  }

  .event-img img {
    width: 85%;
    height: 100%;
    margin-bottom: 10px;
  }

  .conv-box {
    font-size: 13px;
  }

  .about-img {
    left: -50px;
  }

  .nav-link-active::before {
    width: 20%;
    left: 40%;
  }

  .cookie-box {
    font-size: 14px;
  }

  .swiper-wrapper {
    justify-content: start;
  }

  .swiper {
    padding: 0px !important;
  }

  .swiper-slide {
    width: 300px !important;
  }

  .cta-actions {
    margin-top: 10px !important;
  }
}

@media screen and (max-width: 768px) {
  #nav-container {
    margin-top: 10px;
  }

  .logo-img {
    margin-bottom: 10px;
  }

  .context p {
    font-size: 16px;
    padding: 0 15px;
    margin-top: 10px;
    margin-bottom: 0;
  }

  .ellipse {
    width: 760px;
    height: 450px;
    left: -350px;
    transform: rotate(-140deg);
  }

  .context span {
    font-size: 16px;
  }

  .context .subscribe-form {
    padding: 0 10px;
  }

  .hero-ilustration {
    width: 350px;
    height: 310px;
  }

  .hero-ilustration.literature {
    width: 380px;
    height: 300px;
  }

  .upcoming-book {
    height: 430px;
    max-width: 330px;
  }

  .upcoming-book p {
    font-size: 14px !important;
  }

  .upcoming-book h1 {
    font-size: 35px;
    line-height: 25px;
  }

  .green-btn-sec {
    min-height: 40px !important;
    font-size: 16px !important;
    padding-top: 7px !important;
    width: 150px !important;
  }

  .subscribe-form input[type="email"] {
    padding: 8px;
  }

  .subscribe-form input[type="submit"] {
    padding: 8px;
  }

  .upcoming-book .red-btn-sec {
    margin-top: 10px;
  }

  .cta-actions {
    margin-top: 10px !important;
  }

  .future-launch {
    margin-bottom: 20px;
    height: 350px;
    width: 250px;
  }

  #initiatives {
    padding: 0 15px;
  }

  .places-card {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
  }

  .places-card img {
    height: 400px;
    width: 300px;
  }

  .places-card>div {
    display: none;
  }

  .address {
    margin-top: 20px;
    margin-bottom: 35px;
  }

  .red-btn-sec {
    width: 140px;
  }

  .red-btn-light {
    width: 140px;
  }

  .author-description p {
    padding-right: 0px;
  }

  #tours {
    margin-top: 40px;
  }

  .conv-box {
    padding: 20px 15px 0 15px;
  }

  .team-link {
    bottom: 0px;
    font-size: 16px;
  }

  .about-img {
    left: 0;
  }

  .about-img-visible {
    width: 100%;
  }

  .category-desktop {
    display: none;
  }

  .category-mobile {
    display: block;
  }

  .language-desktop {
    display: none;
  }

  .language-mobile {
    display: block;
  }

  .wishlist-btn {
    width: 40px;
    height: 40px;
  }

  .fa-heart {
    font-size: 1.5em !important;
  }

  .filter {
    width: 100% !important;
    max-width: 100%;
    min-height: auto;
    border-radius: 10px;
    margin-bottom: 20px;
    overflow: visible;
    border: none;
  }

  .people-hero {
    max-height: 200px;
  }

  .event-card {
    width: 95% !important;
    margin: 20px auto;
  }

  .events-img {
    left: 0;
    width: 800px;
    height: auto;
  }

  .event-img {
    width: 300px;
    height: auto;
  }

  .bookshop-img {
    width: 80%;
    height: auto;
  }

  .bookshop-img-sm {
    width: 150px;
    height: auto;
  }

  .about-title::before {
    right: 20%;
  }

  .book-preview {
    height: 100%;
  }

  .cookie-box {
    font-size: 13px;
  }

  .book-preview {
    width: 100%;
    bottom: -50%;
    left: 0;
    height: 100%;
  }

  .book-preview-div {
    top: 50%;
  }

  .fa-circle-xmark {
    top: 51%;
  }

  .window-blur {
    display: none;
  }

  .peoples-gif {
    height: 200px !important;
  }

  .swiper-slide {
    width: 100% !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
  }
}